<template>
    <vs-popup :title="$t('permissoes')" :active.sync="visible" id="popup-permission" @close="closePopup" ref="vspopup-per">
      <Grid
          ref="grid-permission-popup"
          :multipleSelect="true"
          :service="permissionService"
          @changedSelection="changedSelection"
          route_name="permissions"
          hide_actions="true"
          order_column="name"
          :request-params="filtersGrid">
        </Grid>
        <!--:request-params="applyFiltersGrid"-->
      <div class="flex flex-row justify-end gap-4">
        <vs-button @click="addPermissions">
          {{ $t('adicionar') }}
        </vs-button>
      </div>
    </vs-popup>
</template>

<script>
import PermissionService from '@/services/api/PermissionService'

export default {
  components: {

  },
  props: {
    visible :{
      type: Boolean,
      default: false
    },
    filters :{
      type: String,
      default: null
    }
  },
  data: () => ({
    permissionService: null,
    selectedPermissions: []
  }),
  computed: {
    filtersGrid(){
      return this.filters
    }
  },
  methods: {
    changedSelection(items) {
      this.selectedPermissions = items
    },
    itemSelected(permission) {
      const permissionIndex = this.selectedPermissions.findIndex((_permission) => _permission === permission.id)

      if (permissionIndex !== -1) {
        const newArray = this._.cloneDeep(this.selectedPermissions)
        newArray.splice(permissionIndex, 1)
        this.selectedPermissions = newArray
      } else {
        this.selectedPermissions.push(permission.id)
      }
    },
    addPermissions() {
      this.$emit('selected', [...this.selectedPermissions])
      this.$emit('close', false)
    },
    closePopup(value){
      this.$emit('close', value)
    },
    applyFiltersGrid(){
      if(this.filters){
        this.getGrid().fillCustomFilters(this.filters)
        this.getGrid().fetchGridData(false)
      }
    },
    getGrid(){
      const gripP = this.$refs['grid-permission-popup']
      return gripP
    }
  },
  beforeMount() {
    this.permissionService = PermissionService.build(this.$vs)
  },
  mounted() {

  },
  watch: {
    visible(val){
      if(val){
        //this.applyFiltersGrid()
      }
    }
  },
}
</script>


<style lang="scss" scoped>
  ::v-deep .vs-popup {
    width: 800px !important;
  }
</style>
