<template>
  <div>
    <vx-card class="mb-4">
      <vs-tabs :color="colorx">
        <vs-tab :label="$t('functionality.title')">
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-2">
              <div class="w-full">
                <vs-input
                  id="functionality_name"
                  class="w-full required"
                  v-validate="'required|max:191'"
                  :label="$t('functionality.name')"
                  v-model="model.name"/>
                <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
              </div>
              <div class="w-full">
                <label class="vs-input--label">{{ $t('functionality.module') }}</label>
                <select-suggestion
                  class="required"
                  column="id,name"
                  model="Module"
                  v-model="module"
                  :appendClearOption="true"
                  placeholderText="Digite o nome ou id do módulo"/>
              </div>
               <div class="w-full">
                <vs-textarea
                  id="functionality_description"
                  :label="$t('functionality.description')"
                  v-model="model.description" />
                <span class="text-danger text-sm" v-show="errors.has('description')">{{errors.first('description')}}</span>
              </div>
            </div>
            <div class="w-full flex gap-2 justify-end">
              <vs-button
                v-if="!isEdit()"
                :disabled="!validateForm"
                v-permission="'functionalities.create'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>
              <vs-button
                v-if="isEdit()"
                :disabled="!validateForm"
                v-permission="'functionalities.edit'"
                @click="createOrUpdate">
                {{ $t('action.save') }}
              </vs-button>

              <vs-button
                type="border"
                @click="cancel">
                {{ $t('common.back') }}
              </vs-button>
            </div>
          </div>
        </vs-tab>
        <vs-tab :label="$t('actions')" v-if="isEdit()">
          <div class="vx-row mb-6" style="padding-top: 30px">
            <div class="vx-col w-full">
              <vs-button
                @click="showPopupPermission = true"
                icon="add">
                {{ $t('adicionar') }}
              </vs-button>
              <vs-divider />
              <Grid
                :ref="'grid-permission'"
                :key="gridKey"
                :service="service"
                route_name="functionalities"
                order_column="name"
                :route_grid_path="permissionsFuncPath"
                :route_delete_path="permissionsFuncPath"
                hide_edit="true"
                :delegate="{destroy: () => destroyPermissionFunctionality}">
              </Grid>
              <div class="w-full flex gap-2 justify-end">
                <vs-button
                  type="border"
                  @click="cancel">
                  {{ $t('common.back') }}
                </vs-button>
              </div>
            </div>
          </div>
          <permission-popup
            :visible="showPopupPermission"
            @selected="addPermissionsList"
            @close="closePopup"
            :filters="filtersGridPermission"/>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

import FunctionalityService from '@/services/api/FunctionalityService'
import PermissionPopup from './../roles/PermissionPopup.vue'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    SelectSuggestion,
    PermissionPopup
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    showPopupPermission: false,
    gridKey: 0,
    module: {
      id: null,
      name: ''
    },
    model: {
      id: null,
      name : '',
      module_id: null,
      description: ''
    }
  }),
  computed: {
    validateForm() {
      return !this.isEmpty(this.model.name)
            && !this.isEmpty(_.get(this.module, 'id'))
            && !this.isEmpty(this.model.description)
    },
    permissionsFuncPath() {
      return `/${this.id}/permissions`
    },
    filtersGridPermission() {
      //return [{key:'filter_not_in', value: 'functionalities'}, {key:'functionality_id', value: this.id}]
      return {
        filter_not_in : 'functionalities',
        functionality_id: this.id
      }
    }
  },
  beforeMount() {
    this.service = FunctionalityService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },

  methods: {
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
          this.module = response.module
          this.gridKey++
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    createOrUpdate() {
      this.$vs.loading()

      const modelData = {
        id: this.model.id,
        name: this.model.name,
        module_id: _.get(this.module, 'id'),
        description: this.model.description,
      }

      this.service.createOrUpdate(modelData).then(
        data => {
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
          this.$router.push(`/functionalities/${data.id}/edit`)
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(
        () => { this.$vs.loading.close() }
      )
    },
    destroyPermissionFunctionality(item) {
      this.$vs.loading()
      this.service.destroyPermissionFunctionality(this.id, item.permission_id).then(
        response => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Successo!',
            text: this.$t('operacao-realizada-com-sucesso'),
            color: 'success',
            iconPack: 'feather',
            position: 'top-center',
            icon: 'icon-check-circle'
          })

          this.gridKey += 1
        },
        error => {
          this.$vs.loading.close()
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-escluir-esta-permissao'))
        }
      )
    },
    cancel() {
      this.$router.push('/functionalities')
    },
    isAdmin() {
      return isAdmin()
    },
    isEdit() {
      return !_.isNil(this.id)
    },
    addPermissionsList(permissionList = null) {
      if (permissionList && this._.isArray(permissionList) && permissionList.length > 0) {
        const permissions = []

        permissionList.forEach(
          (permission) => permissions.push(permission)
        )
        this.addPermissions(permissions)
      }
    },
    addPermissions(permissionIds) {
      this.$vs.loading()
      if (this.id) {
        this.service.addPermissions(this.id, permissionIds).then(
          response => {
            this.notifySuccess(this.$vs, this.$t('messages.success'))
            this.updateGridPermissions()
          },
          error => {
            this.notifyError(this.$vs, 'Não foi possível adicionar a(s) permissião(ões)!')
          }
        ).finally(
          () => {
            this.$vs.loading.close()
          }
        )
      }
    },
    updateGridPermissions() {
      const gridRef = this.$refs['grid-permission']
      if (gridRef) {
        gridRef.fetchGridData()
      }
    },
    closePopup(show) {
      this.showPopupPermission = show
    }
  }
}
</script>

<style>

</style>
